<template>
    <div>
        <div class="index" :class="{ 'index-Side': SideShow }">
            <div class="head" :class="{ 'head-Side': SideShow }">
                <div class="head-box">
                    <img src="@/assets/logo.png" class="my-logo" @click="goTo('/')" />
                    <i @click="SideShow = !SideShow"></i>
                    <!-- <div class="logo"></div> -->
                    <!-- <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t('signIn')}}</button> -->
                    <div class="lang-select" @click="showLangSelect">
                        <span class="lang"> <img :src="nowLangSrc" class="lang-icon" /></span>
                        <div :class="['lang-selectable', { visible: langSelectVisible }]">
                            <span class="lang-li" v-for="item in langMapping" @click.stop="changeLanguage(item)">
                                <img :src="item.img" class="lang-icon" />
                                {{ item.name }}
                            </span>

                        </div>
                    </div>
                </div>
            </div>
            <router-view></router-view>
            <home-footer-phone />
        </div>
        <div class="Side" :class="{ 'Side-show': SideShow }">
            <div class="Side-head">
                <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{ $t('signIn') }}</button>
                <button class="btn btn-primary" @click="$open('login5', $i18n.locale)">{{ $t('signIn5') }}</button>
            </div>
            <div class="Side-ul">
                <div class="Side-ul-li" v-for="(item, index) in sideList" :key="index"
                    @click="goTo(item.path, item, index)">
                    <!-- <img :src="item.imgSrc"> -->
                    {{ item.name }}
                    <i v-if="item.children" @click.stop="toOpen(index)"
                        :class="{ 'drop-down': index == sideOpenIndex }">
                    </i>
                    <ul v-if="item.children" :class="{ 'drop-down-ul': index == sideOpenIndex }">
                        <li v-for="sitem in item.children" @click.stop="goTo(sitem.path)">{{ sitem.name }}</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import homeImg from '@/assets/image/home/phone/home.png'
import weImg from '@/assets/image/home/phone/we.png'
import platformImg from '@/assets/image/home/phone/platform.png'
import transactionImg from '@/assets/image/home/phone/transaction.png'
import communityImg from '@/assets/image/home/phone/community.png'
import activityImg from '@/assets/image/home/phone/activity.png'
import noticeImg from '@/assets/image/home/phone/Notice.png'
import supportImg from '@/assets/image/home/phone/support.png'
import HomeFooterPhone from "../components/footer/footer";
export default {
    name: "phone",
    components: { HomeFooterPhone },
    watch: {
        '$route': {
            handler(v) {
                let app = document.querySelector(".index")
                app.scrollTop = 0
                // document.documentElement.scrollTop = document.body.scrollTop = 0;
            },
            deep: true
        }
    },
    data() {
        return {
            homeImg,
            weImg,
            platformImg,
            transactionImg,
            communityImg,
            activityImg,
            noticeImg,
            supportImg,
            SideShow: false,
            sideOpenIndex: 0,
            // langText: 'En',
            langMapping: [{
                name: "En",
                value: "en",
                img: "/lang/en.png"
            }, {
                name: "ja",
                value: "ja",
                img: "/lang/ja.png"
            }, {
                name: "fr",
                value: "fr",
                img: "/lang/fr.png"
            }, {
                name: "de",
                value: "de",
                img: "/lang/de.png"
            }, {
                name: "vn",
                value: "vn",
                img: "/lang/vn.png"
            }, {
                name: "繁",
                value: "zh_tw",
                img: "/lang/cn2.png"
            }],
            nowLangSrc: "/lang/en.png",
            langSelectVisible: false,
            hiddenTimeoutId: null,
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        langText() {
            // const langMapping = {
            //     // zh: '中',
            //     en: 'En',
            //     ja: 'ja',
            //     fr: "fr",
            //     de: "de",
            //     vn: 'vn',
            //     zh_tw: "繁",
            // }
            // this.langMapping = langMapping;
            return this.lang
        },

        sideList() {
            let list = [
                {
                    name: this.$t("header.home"),
                    imgSrc: this.homeImg,
                    path: '/'
                }, {
                    name: this.$t("header.about"),
                    imgSrc: this.weImg,
                    path: '/phone/about-us',
                    children: [
                        {
                            name: this.$t("header.aboutUs"),
                            path: '/phone/about-us/who'
                        }, {
                            name: this.$t("header.contactUs"),
                            path: '/phone/about-us/contact'
                        }, {
                            name: this.$t("header.ourAdvantages"),
                            path: '/phone/about-us/advantage'
                        }
                    ]
                    // },{
                    //     name: this.$t("header.platform"),
                    //     imgSrc: this.platformImg,
                    //     path: '/phone/platform',
                    //     children: [
                    //         {
                    //             name: 'MT4',
                    //             path: '/phone/platform/MT4'
                    //         }, 
                    //         {
                    //             name: 'MT5',
                    //             path: '/phone/platform/MT5'
                    //         }
                    //     ]
                }, {
                    name: this.$t("header.transaction"),
                    imgSrc: this.transactionImg,
                    path: '/phone/deal',
                    children: [
                        {
                            name: this.$t("header.foreignExchange"),
                            path: '/phone/deal/forex'
                        }, {
                            name: this.$t("header.nobleMetal"),
                            path: '/phone/deal/metal'
                        }, {
                            name: this.$t("header.energy"),
                            path: '/phone/deal/energy'
                        }, {
                            name: this.$t("header.index"),
                            path: '/phone/deal/exponent'
                        }, {
                            name: this.$t("header.digitalCurrency"),
                            path: '/phone/deal/DigitalCurrency'
                        }
                    ]
                }, {
                    name: this.$t("header.community"),
                    imgSrc: this.communityImg,
                    path: '/phone/community',
                    children: [
                        {
                            name: this.$t("header.followingUp"),
                            path: '/phone/community/followingUp'
                        }, {
                            name: this.$t("header.multiAccountManagement"),
                            path: '/phone/community/multiAccountManagement'
                        }
                    ]
                }, {
                    name: this.$t("header.platform"),
                    imgSrc: this.platformImg,
                    path: '/phone/platform',
                    children: [
                        {
                            name: 'MT4',
                            path: '/phone/platform/MT4'
                        },
                        {
                            name: 'MT5',
                            path: '/phone/platform/MT5'
                        }
                    ]
                }, 
                // {
                //     name: this.$t("header.activity"),
                //     imgSrc: this.activityImg,
                //     path: '/phone/activity'
                // }, 
                // {
                //     name: this.$t("header.notice"),
                //     imgSrc: this.noticeImg,
                //     path: '/phone/notice'
                // }, 
                {
                    name: this.$t("header.support"),
                    imgSrc: this.supportImg,
                    path: '/phone/support',
                    children: [
                        {
                            name: this.$t("header.nationalBroker"),
                            path: '/phone/support/agent'
                        }, {
                            name: this.$t("header.outOfGoldIntoGold"),
                            path: '/phone/support/fund'
                        }, {
                            name: this.$t("header.questionAnswering"),
                            path: '/phone/support/faq'
                        }
                    ]
                }
            ]
            return list
        }
    },
    methods: {
        goTo(path, item, index) {
            if (item?.children) {
                this.toOpen(index);
            } else {
                this.SideShow = false;
                this.$router.push({ path });
            }


        },
        toOpen(index) {
            this.sideOpenIndex == index ? this.sideOpenIndex = 0 : this.sideOpenIndex = index
        },
        changeLanguage(item) {
            // this.langText = lang;
            this.$i18n.locale = item.value;
            this.langSelectVisible = false;
            this.nowLangSrc = item.img;
        },
        showLangSelect(a, r) {
            if (this.langSelectVisible) {
                this.langSelectVisible = false;
            } else {
                this.langSelectVisible = true;
                // clearTimeout(this.hiddenTimeoutId);
            }
        },
        hideLangSelect() {
            this.hiddenTimeoutId = setTimeout(() => {
                this.langSelectVisible = false;
            }, 100);
        },
    }
}
</script>

<style scoped lang="scss">
.index {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: all 0.2s;
}

.head {
    width: 100%;
    height: 64px;
    line-height: 64px;
    position: fixed;
    background-color: #1A1A1A;
    top: 0;
    right: 0;
    z-index: 999;
    transition: all 0.2s;

    .head-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        width: 100%;
        height: 100%;

        i {
            position: absolute;
            right: 16px;
            top: 18px;
            display: block;
            width: 28px;
            height: 28px;
            @include bg-image('../assets/image/home/phone/menu');
            background-size: cover;
            transform: rotate(180deg);

        }

        .my-logo {
            height: 26px;
            margin-left: 20px;
        }

        .logo {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 50px;
            height: 18px;
            @include bg-image('../assets/image/home/phone/ft');
            background-size: cover;
            z-index: 1;
        }

        .btn {
            position: absolute;
            top: 10px;
            right: 10px;
            min-width: 60px;
            height: 24px;
            line-height: 24px;
        }
    }

}

.index-Side {
    right: 285px;
    transition: all 0.2s;

    .head-Side {
        right: 285px;
        transition: all 0.2s;
    }
}

.Side {
    position: fixed;
    right: 0;
    top: 0;
    width: 0;
    height: 100vh;
    z-index: 999;
    background-color: #1A1A1A;
    overflow: hidden;
    transition: all 0.2s;
    padding-top: 30px;

    .Side-head {
        width: 100%;
        height: 90px;
        padding-bottom: 33px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        text-align: center;

        .btn {
            line-height: 31px;
            margin-bottom: 8px;
            min-width: 124px;
            height: 31px;
            background-color: #b8874d;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }

    .Side-ul {
        width: 100%;
        height: calc(100vh - 220px);
        overflow-y: auto;
        margin-top: 28px;
        padding-left: 17.5px;

        .Side-ul-li {
            width: 100%;
            min-height: 23px;
            line-height: 23px;
            color: #fff;
            font-size: 14px;
            margin-bottom: 22px;
            position: relative;

            img {
                float: left;
                width: 18px;
                height: 18px;
                margin-right: 10px;
                margin-top: 2.5px;
                image-rendering: -webkit-optimize-contrast;
            }

            i {
                position: absolute;
                right: 10px;
                top: 3px;
                display: block;
                width: 18px;
                height: 18px;
                background: url("../assets/image/home/phone/drop-down.png");
                background-size: cover;
                background-position: center center;
                transition: all 0.2s;
            }

            .drop-down {
                transform: rotate(180deg);
                transition: all 0.2s;
            }

            ul {
                width: 100%;
                height: 0;
                overflow: hidden;
                padding-left: 28px;
                transition: all 0.2s;

                li {
                    width: 100%;
                    height: 22.5px;
                    line-height: 22.5px;
                    font-size: 12px;
                    margin-top: 24px;
                    color: rgba(255, 255, 255, 0.8);
                }
            }

            .drop-down-ul {
                height: auto;
                overflow: hidden;
                transition: all 0.2s;
            }
        }
    }

    span {
        width: 100%;
        float: right;
        text-align: right;
        font-size: 16px;

        b {
            width: 18px;
            height: 18px;
            /*float: left;*/
            display: inline-block;
            background: url("../assets/image/home/phone/conversion.png");
            background-size: cover;
        }
    }
}

.Side-show {
    width: 285px;
    height: 100vh;
    right: 0;
    top: 0;
    /*transition: all 0.2s;*/
    padding: 30px 22.5px;
}

.head-box {
    z-index: 999;
    padding-right: 60px;

    .lang-select {
        @include cp();
        position: relative;

        &:after {
            display: none;
            content: '';
            width: 0;
            height: 0;
            right: -20px;
            top: 5px;
            border: 5px solid transparent;
            border-top-width: 6px;
            border-top-color: #fff;
            position: absolute;

        }

        .lang {
            display: block;
            font-size: 12px;
            text-align: center;
            line-height: 24px;
            width: 24px;
            padding: 3px;
            text-align: center;
            height: 24px;
            // box-shadow: 0 2px 8px 1px rgba(255, 255, 255, 0.2);
            color: #fff;
            border-radius: 5px;
            position: relative;
            z-index: 1;
            cursor: pointer;

            img {
                display: block;
                width: 100%;
            }

            &-selectable {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                // width: 100px;
                padding-top: 32px;
                transition: all .3s;

                .lang {
                    text-align: left;
                }

                .lang-li {
                    line-height: 20px;
                    background: rgba(255, 255, 255, 0.2);
                    padding: 5px 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 20px;

                    img {
                        width: 14px;
                    }
                }

                &.visible {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .btn {
        margin-left: 56px;
        border: none;
        padding: 0 25px;

        &:last-of-type {
            margin-left: 20px;
        }
    }
}
</style>
<template>
  <div class="warpper">
    <div class="buts">
      <div class="but on">
        {{ $t('header.download') }}(MT4)
      </div>
      <div class="but" @click="goTo('/platform/MT5')">
        {{ $t('header.download') }}(MT5)
      </div>
    </div>
    <div class="tit">
      {{ $t('header.download') }}(MT4)
    </div>
    <div class="down-box">
      <div class="down-l">
        <div class="down-l-item mac">
          <div class="down-l-in">
            <p class="t1">macOS</p>
            <!-- <p class="t2">版本号:1.00000</p> -->
            <div class="down-btn" @click="$open('mac')">{{ $t('header.download') }}</div>
          </div>
          <p class="tip">MetaTrader4：<br>
            Mac</p>
        </div>
        <div class="down-l-item window">
          <div class="down-l-in">
            <p class="t1">Windows</p>
            <!-- <p class="t2">版本号:1.00000</p> -->
            <div class="down-btn" @click="$open('pc')">{{ $t('header.download') }}</div>
          </div>
          <p class="tip">MetaTrader4<br>
             {{ $t("newAdd.tip23") }} </p>
        </div>
      </div>
      <div class="down-r">
        <p> {{ $t("newAdd.tip24") }}</p>
        <div class="down-r-bot">

          <div class="down-r-bot-l">
            <div class="word">
              <div class="t1">
                IOS
              </div>
              <!-- <div class="t2">
                版本号:1.00000
              </div> -->
            </div>
            <div class="code"></div>
            <div class="code-box">
              <img src="@/assets/image/platform/QR-iOS.png" alt="iOS">
            </div>
          </div>

          <div class="down-r-bot-r">

            <div class="down-r-item android">
              <div class="word">
                <div class="t1">
                  Android
                </div>
                <!-- <div class="t2">
                  版本号:1.00000
                </div> -->
              </div>
              <div class="code"></div>
              <div class="code-box">
                <img src="@/assets/image/platform/QR-android.png" alt="iOS">
              </div>
            </div>

            <div class="down-r-item ios">
              <div class="word">
                <div class="t1">
                  Ipad os
                </div>
                <!-- <div class="t2">
                  版本号:1.00000
                </div> -->
              </div>
              <div class="code"></div>
              <div class="code-box">
                <img src="@/assets/image/platform/QR-iOS.png" alt="iOS">
              </div>
            </div>

          </div>
        </div>
        <div class="tip">MetaTrader4<br>
          {{ $t("newAdd.tip25") }}</div>
      </div>
    </div>
    <!-- <DesktopMT4 /> -->
    <!-- <MacMT4 /> -->
    <!-- <IpadMT4 />
    <MobileMT4 /> -->
  </div>
</template>

<script>
import DesktopMT4 from './desktop-MT4'
import IpadMT4 from './ipad-MT4'
import MacMT4 from './mac-MT4'
import MobileMT4 from './mobile-MT4'
export default {
  name: 'Forex',
  components: {
    DesktopMT4,
    IpadMT4,
    MacMT4,
    MobileMT4
  },
  data() {
    return {}
  },
  methods: {

    goTo(path) {
      if (localStorage.getItem('screen_width') < 972) {
        path = '/phone' + path;
      }
      this.$router.push({ path })
    }
  }
}
</script>

<style lang="scss" src="./download.scss" scoped></style>

<!-- <style scoped lang="scss">
@import url('./download.css');
</style> -->
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'

import App from './App.vue'
import router from './router'
import {   en, ja, fr, de, vn, zh_tw } from './assets/locales/index'

import 'element-ui/lib/theme-chalk/index.css'
import './assets/style/index.css'

import md5 from 'js-md5'
Vue.prototype.$md5 = md5

Vue.config.productionTip = false

const skipUrl = {
  login: 'https://ib.futailnternational.com/public/login/?l=en-us',
  login5: 'https://ibt5.futailnternational.com/public/login/?l=en-us',
  signUp: 'https://ib.futailnternational.com/public/register/?l=en-us',
  signUp5: 'https://ibt5.futailnternational.com/public/register/?l=en-us',
  android: 'https://ib.ft66fx.com/uploads/file/metatrader4.apk', // 'https://www.ft66fx.com/soft/android_MT4.apk',
  iOS: 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=Futa', // 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=FutaiInternational-Demo,FutaiInternational-Live',
  pc: 'https://download.mql5.com/cdn/web/futai.international.investment/mt4/futaiinternational4setup.exe', // 'https://www.ft66fx.com/soft/futaiinternational4setup.exe',
  mac: 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=Futa', // 'https://www.ft66fx.com/soft/MetaTrader4.dmg',
  android5: 'https://ib.ft66fx.com/uploads/file/metatrader5.apk', // 'https://www.ft66fx.com/soft/android_MT4.apk',
  iOS5: 'https://download.metatrader.com/cdn/mobile/mt5/ios?server=FutaiInternational-Demo,FutaiInternational-LIVE', // 'https://download.metatrader.com/cdn/mobile/mt4/ios?server=FutaiInternational-Demo,FutaiInternational-Live',
  pc5: 'https://download.mql5.com/cdn/web/futai.international.investment/mt5/futaiinternational5setup.exe', // 'https://www.ft66fx.com/soft/futaiinternational4setup.exe',
  mac5: 'https://download.metatrader.com/cdn/mobile/mt5/ios?server=FutaiInternational-Demo,FutaiInternational-LIVE', // 'https://www.ft66fx.com/soft/MetaTrader4.dmg',
  partake: 'https://ib.futailnternational.com/?language=', // 'https://ib.ft66fx.com/register?share_id=102&type=register&ssid=9&time=1652698694792&sign=21f31d1be9d1aa361e79c577f4cc8fb3&language=',
  activity: '/#/activity'
}
Vue.use(Element, { size: 'mini' })
Vue.use(VueI18n)
Vue.prototype.$open = function(key, lang) {
  const openUrl = ['login', 'login5', 'signUp', 'signUp5'].indexOf(key) > -1 ? `${skipUrl[key]}${lang === 'en' ? '' : ''}` : skipUrl[key]
  window && window.open(openUrl)
}

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    // zh: { ...zh },
    en: { ...en },
    ja: { ...ja },
    fr: { ...fr },
    de: { ...de },
    vn: { ...vn },
    zh_tw: { ...zh_tw },
  },
  // silentTranslationWarn: true,
})

window.vue = new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app')

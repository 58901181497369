<template>
  <div class="market-table__wrapper">
    <el-table ref="src-ip-table" class="percentile-report__table" empty-text="No Data" :data="tableData"
      :height="tableConfig.height" v-loading="tableLoading" @row-click="emitRowClick">
      <el-table-column prop="symbol" label="Instrument" min-width="100px" :width="tableConfig.cellWidth">
        <template #default="{ row }">
          <span class="table-symbol">
            {{ row.symbol }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="price" label="Price" :width="tableConfig.cellWidth">
        <template #default="{ row }">
          <span :class="['table-price', row.priceWave, { 'show-bg': row.showBg }]">
            {{ row.price ? row.price : '-' }}
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="day" label="Day" :width="tableConfig.cellWidth">
        <template #default="{ row }">
          <span :class="['table-day', row.dayWave]">
            {{ row.day ? row.day : '-' }}
            <i class="el-icon-top-right" v-show="row.day && row.dayWave === 'increase'"></i>
            <i class="el-icon-bottom-right" v-show="row.day && row.dayWave === 'decline'"></i>
          </span>
        </template>
      </el-table-column>

      <el-table-column prop="week" label="Week" :width="tableConfig.cellWidth">
        <template #default="{ row }">
          <span :class="['table-day', row.weekWave]">
            {{ row.week ? row.week : '-' }}
            <i class="el-icon-top-right" v-show="row.week && row.weekWave === 'increase'"></i>
            <i class="el-icon-bottom-right" v-show="row.week && row.weekWave === 'decline'"></i>
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="!tableConfig.isSimple" prop="trend" label="Trend" align="center">
        <template #default="{ row }">
          <span class="table-chart">
            <table-cell-chart :chart-data="row.trend"></table-cell-chart>
          </span>
        </template>
      </el-table-column>

      <el-table-column v-if="!tableConfig.isSimple && false" prop="trend" label="" width="220px">
        <template>
          <div class="table-operating">
            <button class="btn" @click="signIn">Sell</button>
            <button class="btn" @click="signIn">Buy</button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import TableCellChart from './table-cell-chart'
import { returnFloat } from '@/utils/common'
import { weekFirstOffsetTime, nowOffsetTime, opening } from '@/utils/dateUtils'
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()

export default {
  name: 'markets-table',

  props: {
    tabItem: {
      type: Object,
      default: () => { }
    },

    tableConfig: {
      type: Object,
      default: () => ({
        isSimple: false,
        height: 480,
        cellWidth: "auto"
      })
    }
  },

  components: {
    TableCellChart
  },

  data() {
    return {
      timer: 0,
      symbolTimer: 0,
      tableLoading: false,
      tableData: []
    }
  },

  methods: {
    signIn(e) {
      e.stopPropagation()
      this.$open('signIn', this.$i18n.locale)
    },
    async getPriceBySymbol() {
      clearTimeout(this.symbolTimer)
      let params = {
        symbol_array: this.tabItem.varieties.map(varietie => varietie.apiCode)
      }
      await gbpService.getPriceBySymbol(params).then(data => {

        data.forEach(d => {
          let row = this.tableData.find(t => t.symbol === d.SYMBOL)
          if (row.dayStart) {
            row.priceWave =
              d.BID == parseFloat(row.price) ? '' : d.BID - parseFloat(row.price) > 0 ? 'increase' : 'decline'
            setTimeout(function () {
              row.showBg = true
              setTimeout(function () {
                row.showBg = false
              }, 1000)
            }, Math.ceil(Math.random() * 5000))
            row.day = (Math.abs((d.BID - row.dayStart) / row.dayStart) * 100).toFixed(2) + '%'
            row.dayWave = d.BID - row.dayStart > 0 ? 'increase' : 'decline'
          }
          if (row.weekStart) {
            row.week = (Math.abs((d.BID - row.weekStart) / row.weekStart) * 100).toFixed(2) + '%'
            row.weekWave = d.BID - row.weekStart > 0 ? 'increase' : 'decline'
          }
          row.price = returnFloat(d.BID, d.SYMBOL)
        })

        this.symbolTimer = setTimeout(() => {
          this.getPriceBySymbol()
        }, 5000)
      })
    },
    async getTableData() {
      clearTimeout(this.timer)
      let nowTime = nowOffsetTime(10)
      this.tabItem.varieties.forEach(item => {
        new Promise((resolve, reject) => {
          gbpService
            .getHistoryRatesInfo({
              reqtype: 'historyratesinfo',
              reqid: '100',
              symbol: item.apiCode,
              period: 5,
              fromtime: nowTime - 24 * 60 * 60,
              endtime: nowTime
            })
            .then(res => {
              // console.log(111,res)
              if (!res[0]) return
              let row = this.tableData.find(item => {
                return item.symbol === res[0].symbol
              })
              console.log(111, row)
              if (!row.dayStart) {
                let today = new Date().getDate()
                let todayIndex = 0
                res.forEach((data, idx) => {
                  if (!todayIndex && today === data.date) {
                    todayIndex = idx
                  }
                })
                const [{ data: dayStart }] = res.slice(todayIndex, todayIndex + 1)
                row.dayStart = returnFloat(dayStart[0], res[0].symbol)
              }
              row.trend = res.map(data => {
                return {
                  time: new Date(data.time).getTime(),
                  data: data.data[1]
                }
              })
            })
        }).then(r => {
          console.log(r)
        })
      })
      opening() &&
        (this.timer = setTimeout(() => {
          this.getTableData()
        }, 120000))
    },

    /**
     * 请求每个品种的周一开盘价用来计算周涨跌幅
     */
    async getWeekOpen() {
      let fromtime = weekFirstOffsetTime(-2) + 8 * 60 * 60 + 5 * 60 // 周一08:05开盘
      this.tabItem.varieties.forEach(item => {
        new Promise((resolve, reject) => {
          gbpService
            .getHistoryRatesInfo({
              reqtype: 'historyratesinfo',
              reqid: '100',
              symbol: item.apiCode,
              period: 1,
              fromtime: fromtime,
              endtime: fromtime + 60
            })
            .then(res => {
              // console.log(111,res)
              if (!res[0]) return
              let row = this.tableData.find(item => {
                return item.symbol === res[0].symbol
              })
              const [{ data: weekStart }] = res.slice(0)
              row.weekStart = returnFloat(weekStart[0], item.apiCode)
            })
        }).then(r => {
          console.log(r)
        })
      })
    },

    emitRowClick(row, col) {
      const { apiCode } = this.tabItem.varieties.find(_ => _.name === row.symbol)
      this.$emit('row-cLick', { row, apiCode, name: row.symbol })
      if (col.label == 'Instrument') {
        if (localStorage.getItem('screen_width') >= 1200) {
          this.$router.push({ path: '/deal/kLine', query: { name: row.symbol } })
        } else {
          this.$router.push({ path: '/phone/deal/kLine', query: { name: row.symbol } })
        }
      }
    }
  },

  mounted() {
    // console.log('000',this.tabItem)
    this.tableData = this.tabItem.varieties.map((varietie, i) => {
      return {
        symbol: varietie.apiCode,
        dayStart: null,
        price: null,
        priceWave: '',
        showBg: false,
        day: '',
        dayWave: '',
        weekStart: null,
        week: '',
        weekWave: '',
        trend: []
      }
    })
    this.getTableData()
    this.getWeekOpen()
    this.getPriceBySymbol()
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
    if (this.symbolTimer) {
      clearTimeout(this.symbolTimer)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/deep/::-webkit-scrollbar-track {
  width: 8px;
  background-color: rgba(73, 66, 59, 0);
}

/deep/::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.warpper {
  width: 1350px;
  margin: 0 auto;
  background-color: #000000;
  box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
  border-radius: 10px;
  border: solid 1px #c99859;
  overflow: hidden;
}

/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

/deep/ .el-table--mini .el-table__cell {
  padding: 14px 0;
}

/deep/ .el-table th.el-table__cell {
  background-color: #000000;
}

/deep/ .el-table {
  color: #f1f1f1;
  font-size: 13px;
  font-weight: bold;
}

/deep/ .el-table::before {
  background-color: transparent !important;
}


/deep/ .el-table tr {
  background-color: #111;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: #030303;
}

/deep/ .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
  background-color: #666;
}

/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #999;
}


/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.market-table__wrapper {
  width: 1350px;
  margin: 0 auto;
  background-color: #000000;
  box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
  border-radius: 10px;
  border: solid 1px #c99859;
  overflow: hidden;


  ::v-deep {
    .el-table {

      .el-table__row:hover {

        .table-symbol,
        .table-price {
          color: var(--theme-primary);
        }
      }

      thead,
      th:not(:first-child)>.cell {
        // color: var(--text-light-black);
        text-align: right;
        padding: 11px 0;
      }

      th:first-child>.cell {
        text-align: center;
        // color: var(--text-light-black);
      }

      th:nth-child(5)>.cell {
        // color: var(--text-light-black);
        text-align: inherit;
      }

      tbody {

        td.el-table__cell {
          border: none;
          color: #fff !important;
        }
      }

      .el-table__body tr.hover-row>td.el-table__cell {
        background: rgba(245, 247, 250, 0.1);
      }

      .el-table__empty-text,
      .cell {
        font-size: 14px;
        padding-right: 0;
        padding-left: 0;
        cursor: pointer;
      }

      tr td:nth-child(1) {
        text-align: center;
      }

      tr td:nth-child(2),
      tr td:nth-child(3),
      tr td:nth-child(4) {
        text-align: right;
      }

      .el-table__fixed {
        left: 24px;

        &::before {
          display: none;
        }
      }
    }
  }

  .table-chart {
    text-align: center;
  }

  .table-operating {
    width: 100%;

    .btn {
      width: 66px;
      border-radius: 28px;
      color: var(--text);

      &:hover {
        background-color: var(--error);
        border-color: transparent;
      }
    }

    .btn:last-child {
      margin-left: 8px;

      &:hover {
        background-color: var(--info);
      }
    }
  }
}

/* 大屏幕（大桌面显示器，大于等于 1200px）*/
@media screen and (min-width: 1200px) {
  /* 当前网站以大屏幕方式设计开发 大于1200px 不作变动 */
}

/* 中等屏幕（桌面显示器，大于等于 992px 小于 1200px） */
@media screen and (min-width: 992px) and (max-width: 1200px) {}

/* 小屏幕（平板，大于等于 768px 小于 992px） */
@media screen and (max-width: 992px) {
  .container {
    width: 95vw;

  }

  .percentile-report__table {
    border-radius: 5px;
  }


  /deep/ .el-table {
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
    background: #111;
  }

  /deep/ .el-table th.el-table__cell>.cell {
    font-size: 11px;
    font-weight: normal;
    line-height: 20px;
  }

  /deep/ .el-table td .cell,
  /deep/ .el-table th .cell {
    transform: scale(0.7);
    padding: 0;
  }

  /deep/ .el-table--mini .el-table__cell {
    padding: 0;
  }

  /deep/ .el-table .cell {
    padding: 0;
  }

  /deep/ .el-table th.el-table__cell>.cell {
    padding: 0;
  }
}
</style>

<template>
  <header>
    <div class="head-box" :class="[classes]">

      <img class="logo" src="@/assets/logo.png" />

      <ul :class="['nav', 'flex', { 'force-hidden-menu': isHiddenMenu }]">
        <li :class="[['/'].includes($route.path) ? 'is-active' : '']" @click="goTo('/')">{{ $t('header.home') }}
        </li>
        <li @click="goTo('/about-us')"
          :class="[['/about-us/who', '/about-us/advantage'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.about') }}
          <ul class="sub-menu-list">
            <li class="sub-menu-item" @click.stop="goTo('/about-us/who')">{{ $t('header.aboutUs') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/about-us/contact')">{{ $t('header.contactUs') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/about-us/advantage')">{{ $t('header.ourAdvantages') }}
            </li>
          </ul>
        </li>

        <li @click="goTo('/deal')"
          :class="[['/deal/forex', '/deal/metal', '/deal/energy', '/deal/exponent'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.product') }}
          <ul class="sub-menu-list">
            <li class="sub-menu-item" @click.stop="goTo('/deal/forex')">{{ $t('header.foreignExchange') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/deal/metal')">{{ $t('header.nobleMetal') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/deal/energy')">{{ $t('header.energy') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/deal/exponent')">{{ $t('header.index') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/deal/DigitalCurrency')">{{ $t('header.digitalCurrency') }}
            </li>
          </ul>
        </li>
        <li @click="goTo('/community')"
          :class="[['/community/followingUp', '/community/multiAccountManagement'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.community') }}
          <ul class="sub-menu-list">
            <li class="sub-menu-item" @click.stop="goTo('/community/followingUp')">{{ $t('header.followingUp') }}
            </li>
            <li class="sub-menu-item" @click.stop="goTo('/community/multiAccountManagement')">
              {{ $t('header.multiAccountManagement') }}</li>
          </ul>
        </li>
        <li @click="goTo('/platform')"
          :class="[['/platform/MT4', '/platform/MT5'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.download') }}
          <ul class="sub-menu-list">
            <li class="sub-menu-item" @click.stop="goTo('/platform/MT4')">MT4</li>
            <li class="sub-menu-item" @click.stop="goTo('/platform/MT5')">MT5</li>

          </ul>
        </li>
        <!-- <li @click="goTo('/activity')" :class="[['/activity'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.activity') }}</li> -->
        <!-- <li @click="goTo('/notice')" :class="[['/notice'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.notice') }}</li> -->
        <li @click="goTo('/support')"
          :class="[['/support/agent', '/support/fund', '/support/faq'].includes($route.path) ? 'is-active' : '']">
          {{ $t('header.support') }}
          <ul class="sub-menu-list">
            <li class="sub-menu-item" @click.stop="goTo('/support/agent')">{{ $t('header.nationalBroker') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/support/fund')">{{ $t('header.outOfGoldIntoGold') }}</li>
            <li class="sub-menu-item" @click.stop="goTo('/support/faq')">{{ $t('header.questionAnswering') }}</li>
          </ul>
        </li>
      </ul>
      <div class="operating flex flex__align-item-center">
        <div class="lang-select" @mouseenter="showLangSelect" @mouseleave="hideLangSelect">
          <span class="lang">
            <img :src="nowLangSrc" class="lang-icon" />
            <!-- {{ langText }} -->
          </span>
          <div :class="['lang-selectable', { visible: langSelectVisible }]">

            <span class="lang-li" v-for="item in langMapping" @click="changeLanguage(item)">
              <img :src="item.img" class="lang-icon" />
              {{ item.name }}
            </span>
          </div>
        </div>
        <button class="header-btn btn-4" @click="$open('login', $i18n.locale)">{{ $t('signIn') }}</button>
        <button class="header-btn btn-5" @click="$open('login5', $i18n.locale)">{{ $t('signIn5') }}</button>
      </div>


    </div>

  </header>
</template>

<script>


export default {
  name: 'home-header',
  data() {
    return {
      langSelectVisible: false,
      hiddenTimeoutId: null,
      isHiddenMenu: false,
      classes: '',
      langMapping: [{
        name: "En",
        value: "en",
        img: "/lang/en.png"
      }, {
        name: "ja",
        value: "ja",
        img: "/lang/ja.png"
      }, {
        name: "fr",
        value: "fr",
        img: "/lang/fr.png"
      }, {
        name: "de",
        value: "de",
        img: "/lang/de.png"
      }, {
        name: "vn",
        value: "vn",
        img: "/lang/vn.png"
      }, {
        name: "繁",
        value: "zh_tw",
        img: "/lang/cn2.png"
      }],
      nowLangSrc: "/lang/en.png"
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },

    langText() {

      //  {
      //   // zh: '中',
      //   en: 'En',
      //   ja: 'ja',
      //   fr: "fr",
      //   de: "de",
      //   vn: 'vn',
      //   zh_tw: "繁",
      // }

      return this.lang;
    },

  },
  mounted() {
    let _self = this;
    window.onscroll = function () {
      var _top = document.documentElement.scrollTop || document.body.scrollTop;
      console.log(_top)
      if (_top > 92) {
        _self.classes = 'floating-roof'
      } else {
        _self.classes = ''
      }
    }
  },
  methods: {
    changeLanguage(item) {
      this.$i18n.locale = item.value;
      this.langSelectVisible = false;
      this.nowLangSrc = item.img;
    },
    showLangSelect(a, r) {
      this.langSelectVisible = true;
      clearTimeout(this.hiddenTimeoutId)
    },
    hideLangSelect() {
      this.hiddenTimeoutId = setTimeout(() => {
        this.langSelectVisible = false;
      }, 300);
    },
    goTo(path) {
      this.$router.push({ path });
      this.isHiddenMenu = true;
      setTimeout(() => {
        this.isHiddenMenu = false;
      }, 300);

    }
  },
}
</script>

<style scoped lang="scss">
header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  box-shadow: 8px 12px 62px 0px rgba(0, 0, 0, 0.04);
}

.header-btn {
  border: none;
  background-color: transparent;
  outline: none;
  min-width: 136px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 11px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-4 {
  background-color: #c99859;
  color: #000;
}

.btn-5 {
  background-color: #1f1f1f;
  color: #fff;
}

.head-box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  .logo {
    width: 100px;
    user-select: none;
  }

  .nav {
    height: 100%;
    margin-left: 60px;

    &>li {
      @include cp();

      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      padding: 0 5px;
      min-width: 130px;
      text-align: center;
      color: rgba(255, 255, 255, .6);
      position: relative;


      &.is-active {
        color: #fff;
        background-color: #333;
      }

      &:hover {
        color: #fff;
      }


      &:hover .sub-menu-list {
        display: block;
        animation: fade-in .3s;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fade-out {
      from {
        height: 100%;
        opacity: 1;
      }

      to {
        height: 0;
        opacity: 0;
        display: none;
      }
    }

    &.force-hidden-menu .sub-menu-list {
      display: none !important;
    }


    .sub-menu-list {
      display: none;
      position: absolute;
      top: 80%;
      padding: 10px 0;
      background-color: rgba(0, 0, 0, 0.7);
      transform: translate3d(-10px, 0, 0);
      border-radius: 3px;
      z-index: 999;

      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: #fff5;
        position: absolute;
        top: -10px;
        left: 50%;
      }

      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: 20px;
        width: 50%;
        background-color: transparent;
        top: -20px;
      }

      .sub-menu-item {
        min-width: 200px;
        padding: 8px 12px;
        font-size: 14px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .operating {
    z-index: 999;
    gap: 20px;
    margin-left: 60px;

    .lang-select {
      @include cp();
      position: relative;

      &:after {
        display: none;
        content: '';
        width: 0;
        height: 0;
        right: -20px;
        top: 5px;
        border: 5px solid transparent;
        border-top-width: 6px;
        border-top-color: #fff;
        position: absolute;

      }

      .lang {
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 24px;
        width: 24px;
        height: 24px;
        padding:2px;
        box-shadow: 0 2px 8px 1px rgba(255, 255, 255, 0.2);
        color: #fff;
        border-radius: 50%;
        position: relative;
        z-index: 1;
        cursor: pointer;

        img {
          display: block;
          width: 100%;
        }

        &-selectable {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          padding-top: 32px;
          transition: all .3s;

          .lang-li {
            line-height: 20px;
            background: rgba(255, 255, 255, 0.2);
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            img {
              width: 20px;
            }
          }

          &.visible {
            display: inline-block;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .btn {
      margin-left: 56px;
      border: none;
      padding: 0 25px;

      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}

.floating-roof {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  margin: auto;
  background-color: #1d1c19;
  z-index: 999;
  /*overflow: hidden;*/
  padding: 0 0;
  height: 80px;

  .container {
    margin-top: 0;
  }

  .sub-menu-list {
    background-color: #0009 !important;
  }

}

@media screen and (max-width:1460px) {
  .head-box {
    .nav {
      li {
        font-size: 12px;
        min-width: 90px;
      }
    }
  }

  .head-box .nav .sub-menu-list .sub-menu-item {
    min-width: 150px;
    font-size: 12px;
  }

  .operating {
    margin-left: 50px;
  }

  .header-btn {
    font-size: 12px;
    padding: 0 5px;
  }
}

@media screen and (max-width:992px) {
  .head-box {
    display: none;
  }
}
</style>

<template>
  <div class="register-process">
    <div class="w1200">
      <div class="top" v-html='$t("newAdd.tip14")'>
        <!-- {{ $t("startSlider.title2")}} -->
        <!-- How to start trading<br>
        in FUTAI -->
        <!-- <div class="des">{{ $t("support.register.service") }}</div> -->
        <!-- <div class="more">More <img src="@/assets/image/support/right-arrow.png"></div> -->
      </div>
      <div class="bottom">
        <div class="item">
          <img src="@/assets/image/support/01.png">
          <img src="@/assets/img/support/a.png" class="small">
          <div class="flow">{{ $t("support.register.tab1") }}</div>

        </div>
        <div class="item">
          <img src="@/assets/image/support/02.png">
          <img src="@/assets/img/support/b.png" class="small">
          <div class="flow">{{ $t("support.register.tab2") }}</div>

        </div>
        <div class="item">
          <img src="@/assets/image/support/03.png">
          <img src="@/assets/img/support/c.png" class="small">
          <div class="flow">{{ $t("support.register.tab3") }}</div>

        </div>
        <div class="item">
          <img src="@/assets/image/support/04.png">
          <img src="@/assets/img/support/d.png" class="small">
          <div class="flow">{{ $t("support.register.tab4") }}</div>
        </div>
        <div class="item">
          <img src="@/assets/image/support/05.png">
          <img src="@/assets/img/support/e.png" class="small">
          <div class="flow">{{ $t("support.register.tab5") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
    }
  }
}
</script>
<style scoped lang="scss">
.register-process {
  height: 534px;
  background-size: 100% 100%;
  margin-bottom: 276px;
  @include bg-image('../../assets/img/support/bg');

  .w1200 {
    width: 80%;
    position: relative;
    margin: 0 auto;
    padding-top: 1px;


    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 115px;
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 60px;
      font-weight: bold;
      line-height: 86px;
      color: #ffffff;

      .des {
        font-size: 36px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
      }

      .more {
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-left: 7px;
        }
      }
    }

    .bottom {
      width: 1689px;
      height: 388px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #000000;
      box-shadow: 8px 12px 29px 0px rgba(201, 152, 89, 0.04);
      border: solid 2px #c99859;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 100px;

      .item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .small {
          margin-top: 46px;
          width: 63px;
        }

        .flow {
          font-size: 20px;
          font-weight: normal;
          color: #FFFFFF;
          line-height: 28px;
          margin-top: 49px;
          margin-bottom: 16px;
        }

        .line-blue {
          width: 64px;
          height: 8px;
          background: #0D73D6;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .register-process {


    .w1200 {
      width: 95vw;

    }
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {}

@media screen and (max-width: 992px) {
  .register-process {
    height: 270px;

    .w1200 {
      .top {
        font-family: MicrosoftYaHeiUI-Bold;
        font-size: 20px;
        font-weight: bold;
        line-height: 31px;
        color: #ffffff;
        box-shadow: 0px 14px 62px 0px rgba(0, 0, 0, 0.04);
        margin-top: 60px;
      }

      .bottom {
        width: 94%;
        padding:20px;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: 20px;
        margin-top: 50px;
        height:auto;

        img {
          display: none;
        }

        .item {
          justify-content: flex-start;

          .small {
            display: block;
            width: 38px;
            margin-top: 0;
          }

          .flow {
            font-size: 11px;
            line-height: 20px;
            text-align: center;
            margin-top: 10px;
          }
        }

      }
    }
  }
}
</style>
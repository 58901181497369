<template>
  <div class="warpper">
    <div class="item-product" v-for="(item, index) in otherProduct.filter(x => x.path != source)" :key="index">
      <div class="top">
        <div class="title">{{ item.name }}</div>
        <img @click="goTo(item.path)" src="@/assets/img/deal/arrow.png">
      </div>
      <p>{{ item.info }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    source: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    otherProduct() {
      return [
        {
          name: this.$t("deal.title4"),
          info: this.$t("deal.describe4"),
          path: '/deal/forex'
        }, {
          name: this.$t("deal.title1"),
          info: this.$t("deal.describe1"),
          path: '/deal/metal'
        }, {
          name: this.$t("deal.title2"),
          info: this.$t("deal.describe2"),
          path: '/deal/energy'
        }, {
          name: this.$t("deal.title3"),
          info: this.$t("deal.describe3"),
          path: '/deal/exponent'
        }, {
          name: this.$t("deal.title5"),
          info: this.$t("deal.describe5"),
          path: '/deal/DigitalCurrency'
        }
      ]
    }
  },
  methods: {
    goTo(path) {
      this.$router.push({ path })
    }
  }
}
</script>
<style lang="scss" scoped>
.warpper {
  width: 1351px;
  margin: 0 auto;
  display: flex;
  gap: 15px;

  .item-product {
    width: 326px;
    height: 436px;
    background-color: #000000;
    box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
    border-radius: 10px;
    border: solid 1px #c99859;
    padding: 45px 14px;
    flex: 1;

    &:last-child {
      margin-right: 0;
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 20px;
        color: #fff;
        font-weight: bold;
      }
    }

    p {
      font-size: 15px;
      font-weight: bold;
      line-height: 30px;
      color: rgba(255, 255, 255, 0.6);
      margin-top: 20px;
    }

    &:hover {



      p {
        color: rgba($color: #fff, $alpha: 0.6);
      }
    }

    img {
      cursor: pointer;
    }
  }
}




@media screen and (max-width: 992px) {
  .warpper {
    width: 95vw;
    flex-wrap: wrap;

    .item-product {
      flex: none;
      width: 45%;
      margin-bottom: 10px;
      padding: 20px 10px;
      height: 340px;

      p {
        font-size: 11px;
        line-height: 16px;
        font-weight: normal;
      }

      img {
        width: 20px;
      }

      .top {
        .title {
          font-size: 15px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
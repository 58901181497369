<template>
  <div>
    <div class="home-banner">
      <video class="multimedia-hero__video" preload="auto" playsinline="" autoplay="" data-autoplay-desktop=""
        data-autoplay-mobile="" muted="" loop="loop" data-loaded="true" src="../assets/img/home/1.mp4">
      </video>
      <div class="home-in">
        <div class="home-top">
          <img src="../assets/img/home/left.png" />
          <div class="r">
            <div class="t6" v-html='$t("newAdd.tit")'></div>
            <div class="t7">{{$t("newAdd.tip")}}</div>
            <div class="buts">
              <button class="common-but but-now" @click="$open('signUp', $i18n.locale)">
                <img src="@/assets/img/community/user.png" />
                {{ $t("common.registerNow") }}
              </button>
              <button class="common-but but-now gray" @click="$open('signUp5', $i18n.locale)">
                <img src="@/assets/img/community/login.png" />
                {{ $t("common.registerNow5") }}
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="home-bot">
          <div class="bot-box">
            <img src="../assets/img/home/a.png" />
            <div class="word"> {{ $t("newAdd.tit1") }}</div>
          </div>
          <div class="bot-box">
            <img src="../assets/img/home/b.png" />
            <div class="word">{{ $t("newAdd.tit2") }}</div>
          </div>
          <div class="bot-box">
            <img src="../assets/img/home/c.png" />
            <div class="word">{{ $t("newAdd.tit3") }}</div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <homer-slider /> -->
    <gpb-usd />
    <advantage />
    <div class="flex-box">
      <div class="tit3">{{ $t("newAdd.tip6") }}<br>
        #FUTAI</div>
      <div class="flex-in">
        <div class="block">
          <img src="../assets/img/home/1.png" />
          <p class="tit4">{{ $t("newAdd.tip7") }}</p>
          <p class="tit5">{{ $t("newAdd.tip8") }}</p>
          <div class="my-but" @click="$open('signUp', $i18n.locale)">{{ $t("login.learnMore") }}</div>
        </div>
        <div class="block">
          <img src="../assets/img/home/2.png" />
          <p class="tit4">{{ $t("newAdd.tip9") }}</p>
          <p class="tit5">{{ $t("newAdd.tip10") }}</p>
          <div class="my-but" @click="$open('signUp', $i18n.locale)">{{ $t("login.learnMore") }}</div>
        </div>
        <div class="block">
          <img src="../assets/img/home/3.png" />
          <p class="tit4">{{ $t("newAdd.tip11") }}</p>
          <p class="tit5">{{ $t("newAdd.tip8") }}</p>
          <div class="my-but" @click="$open('signUp', $i18n.locale)">{{ $t("login.learnMore") }}</div>
        </div>
        <div class="block">
          <img src="../assets/img/home/4.png" />
          <p class="tit4">{{ $t("newAdd.tip10") }}</p>
          <p class="tit5">{{ $t("newAdd.tip8") }}</p>
          <div class="my-but" @click="$open('signUp', $i18n.locale)">{{ $t("login.learnMore") }}</div>
        </div>
      </div>
    </div>
    <!-- <footer-slider /> -->
    <div class="start-box">
      <div class="tit">
        {{ $t("newAdd.tip12") }}
      </div>
      <div class="tit2" v-html='$t("newAdd.tip13")'>
       
      </div>
      <div class="buts">
        <button class="common-but but-now" @click="$open('signUp', $i18n.locale)">
          <img src="@/assets/img/community/user.png" />
          {{ $t("common.registerNow") }}
        </button>
        <button class="common-but but-now gray" @click="$open('signUp5', $i18n.locale)">
          <img src="@/assets/img/community/login.png" />
          {{ $t("common.registerNow5") }}
        </button>
      </div>
    </div>
    <!-- <start-trading-slider /> -->
    <RegisterProcess></RegisterProcess>
    <contact-us />
  </div>
</template>

<script>
import RegisterProcess from '../components/support/register-process'
import HomerSlider from '@/components/home/slider'
import GpbUsd from '@/components/home/gpb-usd/gpb-usd'
import Advantage from '@/components/home/advantage'
import StartTradingSlider from '@/components/home/start-trading-slider'
import ContactUs from '@/components/home/contact-us'
import FooterSlider from '@/components/home/footer-slider'

export default {
  name: 'Home',
  components: {
    HomerSlider,
    GpbUsd,
    Advantage,
    // Markets,
    StartTradingSlider,
    ContactUs,
    // NewsList,
    FooterSlider,
    RegisterProcess
  },
}
</script>
<style scoped lang="scss">
.home-banner {
  width: 100%;
  height: auto;
  position: relative;

  .home-in {
    width: 1396px;
    position: absolute;
    top: 134px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);

    .home-bot {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 114px;

      .bot-box {
        position: relative;
        margin-top: 100px;

        .word {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 8%;
          display: flex;
          text-align: right;
          align-items: center;
          justify-content: flex-end;
          font-size: 18px;
          font-weight: bold;
          color: #1f1f1f;
        }
      }
    }

    .home-top {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 74px;

      .r {
        .t6 {
          font-family: CenturyGothic-Bold;
          font-size: 80px;
          font-weight: bold;
          line-height: 99px;
          color: #ffffff;
        }

        .t7 {
          width: 648px;
          font-size: 32px;
          line-height: 50px;
          color: #ffffff;
          padding-top: 20px;
        }
      }

    }
  }
}

.home-banner video {
  -o-object-fit: cover;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  height: auto;
  width: 100%;
  overflow: hidden;
}

.flex-box {
  margin-bottom: 157px;

  .tit3 {
    font-family: MicrosoftYaHeiUI-Bold;
    font-size: 100px;
    font-weight: bold;
    line-height: 120px;
    color: #ffffff;
    padding: 200px 0 98px 0;
    text-align: center;
  }

  .flex-in {
    width: 1677px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 58px;

    .block {
      width: 375px;
      height: 431px;
      background-color: #000000;
      border: solid 2px #c99859;
      border-radius: 10px;
      position: relative;

      img {
        height: 109px;
        display: block;
        margin: 40px auto 0 auto;
      }

      .tit4 {
        width: 90%;
        margin: 0 auto;
        font-family: MicrosoftYaHeiUI-Bold;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        color: #ffffff;
        text-align: center;
        padding-top: 20px;
      }

      .tit5 {
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        color: #ffffff;
        text-align: center;
        padding-top: 35px;
      }

      .my-but {
        cursor: pointer;
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        width: 240px;
        height: 58px;
        line-height: 54px;
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        border-radius: 10px;
        border: solid 2px #c99859;
        box-shadow: 0 10px 20px 0 rgba(227, 188, 16, 0.5);
      }
    }
  }
}

.start-box {
  width: 1677px;
  height: 438px;
  margin: 0 auto;
  background-color: #000000;
  margin-bottom: 200px;
  background: url(../assets/img/home/banner.png) no-repeat center center;
  background-size: 100% 100%;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.tit {
  font-size: 40px;
  line-height: 40px;
  padding-top: 65px;
}

.tit2 {
  font-family: MicrosoftYaHeiUI-Bold;
  font-size: 48px;
  line-height: 63px;
  padding-top: 46px;
}

.buts {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  gap: 39px;
}

.but-now {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.but-now.gray {
  background-color: #1f1f1f;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .home-banner {
    height: 600px;
    margin-top: 64px;

    video {
      height: 600px;
    }

    .home-in {
      width: 95vw;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      flex-direction: column;
      top: 40px;

      .home-bot {
        display: none;
      }

      .home-top {
        flex-wrap: wrap;

        img {
          width: 70%;
          margin: 0 auto;
          display: block;
        }

        .common-but img {
          width: 15px;
        }

        .r {
          .t7 {
            font-size: 15px;
            width: 100%;
            line-height: 26px;
          }

          .t6 {
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .flex-box {
    margin-bottom: 50px;
  }

  .flex-box .flex-in {
    width: 95vw;
    flex-wrap: wrap;
    gap: 20px;

    .block {
      width: 45%;
      height: 300px;

      img {
        margin: 20px auto 5px auto;
        height: 50px;
      }

      .tit4 {
        font-size: 12px;
        line-height: 22px;
        padding-top: 10px;
      }

      .tit5 {
        font-size: 12px;
        line-height: 22px;
        padding-top: 10px;
      }

      .my-but {
        width: 60%;
        height: 36px;
        line-height: 34px;
        font-size: 11px;
        border: solid 1px #c99859;
      }
    }
  }

  .flex-box .tit3 {
    padding: 30px 0;
    font-size: 20px;
    line-height: 40px;
    box-sizing: content-box;
  }

  .start-box {
    width: 90%;
    height: 220px;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .tit {
      font-size: 18px;
      padding-top: 0;
    }

    .tit2 {
      font-size: 13px;
      padding-top: 20px;
      line-height: 26px;
    }


  }
}

.buts {
  gap: 12px;
  margin-top: 20px;
}

.common-but {
  font-size: 11px;
  padding: 0 10px;

  img {
    width: 15px;
  }
}
</style>

<template>
  <div class="contact-us container">
    <div class="box">
      <div class="form-left">
        <img src="../../assets/img/home/email.png" alt="" srcset="" />
        <p class="title">{{ $t('home.contactus.customerServiceEmail') }}</p>
        <p class="email">service@futailnternational.com</p>
      </div>
      <div class="form-right">
        <div class="title">{{ $t('home.contactus.contactus') }}</div>
        <el-form label-position="top" :model="contactForm" :rules="rules" size="medium" ref="contactForm"
          :label-width="screenWidth > 992 ? '140px' : '80px'" :style="{ width: screenWidth > 992 ? '420px' : '88%' }"
          class="demo-ruleForm">
          <el-form-item :label="$t('home.contactus.name')" prop="name">
            <el-input v-model="contactForm.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.email')" prop="email">
            <el-input v-model="contactForm.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.phone')" prop="phone">
            <el-input v-model="contactForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('home.contactus.problem')" prop="desc">
            <el-input type="textarea" :rows="4" v-model="contactForm.desc" style="height: 100px;"></el-input>
          </el-form-item>
          <el-form-item>
            <button class="common-but" @click="submitForm('contactForm')">{{ $t('home.contactus.sendNow')
              }}</button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { GbpService } from '@/service/gbp'
const gbpService = new GbpService()
export default {
  name: 'contact-us',
  data() {
    return {
      contactForm: {
        name: '',
        email: '',
        phone: '',
        desc: ''
      }
    }
  },
  computed: {
    rules() {
      return {
        name: [{ required: true, message: this.$t('common.enterName'), trigger: 'blur' }],
        email: [
          { required: true, message: this.$t('common.enterEmail'), trigger: 'blur' },
          { type: 'email', message: this.$t('common.enterEmail'), trigger: 'blur' }
        ],
        phone: [{ required: true, message: this.$t('common.enterPhone'), trigger: 'blur' }],
        desc: [{ required: false, message: this.$t('common.enterName'), trigger: 'blur' }]
      }
    },
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let time = new Date().getTime()
          let key = 'ft' + this.contactForm.phone.length + this.contactForm.phone + time
          let params = {
            name: this.contactForm.name,
            email: this.contactForm.email,
            phone: this.contactForm.phone,
            question: this.contactForm.desc,
            time: time,
            sign: this.$md5(key)
          }
          gbpService.sendQuestion(params).then(res => {
            console.log(res);
            if (res) {
              this.contactForm = {
                name: '',
                email: '',
                phone: '',
                desc: ''
              }
              alert(this.$t('common.sendSuccess'))
            } else {
              alert(this.$t('common.sendFail'))
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.contact-us {
  background: url(../../assets/img/home/banner2.png);
  background-size: 100% 100%;
  width: 100%;
  height: 584px;
  margin-top: 360px;
  margin-bottom: 260px;

  .box {
    width: 1320px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: flex-start;


    .form-left {
      width: 500px;
      padding-top: 58px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      color: #ffffff;

      img {
        margin: 0 auto;
        display: block;
      }

      .title {
        margin-bottom: 15px;
      }

    }

    .form-right {
      width: 500px;
      height: 705px;
      background: url(../../assets/img/home/contact.png) no-repeat center center;
      background-size: 100% 100%;
      position: absolute;
      top: 60px;
      right: 0;

      .title {
        font-family: MicrosoftYaHeiUI-Bold;
        font-size: 32px;
        font-weight: bold;
        line-height: 30px;
        color: #ffffff;
        padding-top: 43px;
        text-align: center;

        ::after {
          content: 'Contact Us';
          color: rgba(0, 0, 0, .06);
        }
      }

      /deep/ .el-form {
        width: 420px;
        margin: 0 auto;
      }

      /deep/ .el-form-item {
        margin-bottom: 16px;
      }

      /deep/ .el-input--medium .el-input__inner,
      /deep/ .el-textarea__inner {
        background: #F3F5F7;
        border-radius: 2px;
      }

      /deep/ .el-form--label-top .el-form-item__label {
        font-family: MicrosoftYaHeiUI-Bold;
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        padding: 19px 0;
        color: #ffffff;

      }

      /deep/ .el-form-item__error {
        color: red;
      }

      /deep/ .el-input__inner {
        width: 418px;
        height: 46px;
        background-color: #ffffff;
        box-shadow: 8px 12px 29px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px !important;
        border: none !important;
      }

      /deep/ .el-form-item__content {
        border-radius: 8px !important;
        border: none !important;
      }
    }

    .common-but {
      width: 418px;
      height: 80px;
      margin: 0 auto;
      background-color: #000000;
      box-shadow: 8px 12px 29px 0px rgba(255, 255, 255, 0.15);
      border-radius: 8px;
      text-align: center;
      font-family: MicrosoftYaHeiUI-Bold;
      font-size: 24px;
      font-weight: bold;
      line-height: 80px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 992px) {
  .contact-us {
    margin-top: 50px;

    .box {
      width: 95vw;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .common-but {
        width: 100%;
        line-height: 40px;
        height: 40px;
        font-size: 15px;
      }

      .form-left {
        width: 70vw;
        font-size: 12px;

        img {
          width: 144px;
        }
      }

      .form-right {
        width: 85vw;
        height: 490px;
        top: 30px;
        position: relative;

        .title {
          font-size: 20px;
          padding-top: 20px;
        }

        /deep/ .el-input__inner {
          width: 100%;
          height: 32px;
          line-height: 32px;
        }

        /deep/ .el-form--label-top .el-form-item__label {
          font-size: 13px;
          padding: 5px 0;
        }

        /deep/ .el-form--label-top .el-form-item__label {
          box-shadow: none;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <AboutBan :bg="2" :title="title"></AboutBan>

    <div class="service">
      <div class="service_left">
        <img src="@/assets/img/community/up.png" class="up" />
        <h4>{{ $t("aboutUs.ourAdvantage.service1") }}</h4>
        <p>{{ $t("aboutUs.ourAdvantage.describe1") }}</p>
        <div class="down">
          <img src="@/assets/img/community/down.png" class="down-img" />
        </div>
      </div>
      <div class="service_right">
        <img src="@/assets/img/about/1.png" />
        <img src="@/assets/img/community/a.png" class="top-img" />
      </div>
    </div>


    <div class="chart-content">
      <line-chart></line-chart>
      <button class="common-but" @click="$open('login', $i18n.locale)">{{ $t("aboutUs.ourAdvantage.trading")
        }}</button>
    </div>


    <div class="service">
      <div class="service_left">
        <img src="@/assets/img/community/up.png" class="up" />
        <h4>{{ $t("aboutUs.ourAdvantage.service2") }}</h4>
        <p>{{ $t("aboutUs.ourAdvantage.describe2") }}</p>
        <div class="down">
          <img src="@/assets/img/community/down.png" class="down-img" />
        </div>
      </div>
      <div class="service_right">
        <img src="@/assets/img/about/2.png" />
        <img src="@/assets/img/community/a.png" class="top-img" />
      </div>
    </div>

    <div class="service">
      <div class="service_left">
        <img src="@/assets/img/community/up.png" class="up" />
        <h4>{{ $t("aboutUs.ourAdvantage.service3") }}</h4>
        <p>{{ $t("aboutUs.ourAdvantage.describe3") }}</p>
        <div class="down">
          <img src="@/assets/img/community/down.png" class="down-img" />
        </div>
      </div>
      <div class="service_right">
        <img src="@/assets/img/about/3.png" />
        <img src="@/assets/img/community/a.png" class="top-img" />
      </div>
    </div>

    <div class="full-slider">
      <img src='@/assets/image/logo/FT.png' class="logo" />
      <p style="background: #999;">{{ $t("aboutUs.ourAdvantage.describe4") }}</p>
    </div>
    <button class="common-but my-but" @click="$open('login', $i18n.locale)">{{ $t("aboutUs.ourAdvantage.trading")
      }}</button>

  </div>
</template>

<script>
import LineChart from '@/components/home/gpb-usd/gpb-chart';
import AboutBan from './AboutBan.vue';
export default {
  name: 'our-advantage',

  components: {
    LineChart,
    AboutBan
  },
  // data() {
  //   return {
  //     title: this.$t("header.ourAdvantages")
  //   }
  // },
  computed: {
    title() {
      return this.$t('header.ourAdvantages');
    }
  }
}
</script>
<style lang="scss" src="./about.scss" scoped></style>
<style scoped lang="scss">
.my-but {
  display: block;
  margin: 60px auto 0 auto;
}

.chart-content {
  width: 1350px;
  background-color: #000000;
  box-shadow: 0px 0px 21px 0px rgba(201, 152, 89, 0.52);
  border-radius: 10px;
  border: solid 1px #c99859;
  margin: 100px auto 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .common-but {
    margin: 30px auto;
  }
}

@media screen and(max-width: 992px) {
  .chart-content {
    width: 95vw;
    padding:5px;
  }
}
</style>
<template>
  <div>
    <AboutBan :title="title"></AboutBan>
    <div class="service">
      <div class="service_left">
        <img src="@/assets/img/community/up.png" class="up" />
        <h4>{{ $t("header.aboutUs") }}</h4>
        <p>{{ $t("aboutUs.title1") }}
          <br> {{ $t("aboutUs.whoAreWe.title1") }}
        </p>
        <div class="down">
          <img src="@/assets/img/community/down.png" class="down-img" />
        </div>
      </div>
      <div class="service_right">
        <img src="@/assets/img/about/banner.png" />
        <img src="@/assets/img/community/a.png" class="top-img" />
      </div>
    </div>

    <div class="service">
      <div class="service_right">
        <img src="@/assets/img/about/contact.png" />
        <img src="@/assets/img/community/a.png" class="top-img l" />
      </div>
      <div class="service_left">
        <img src="@/assets/img/community/up.png" class="up" />
        <img src="../../assets/image/about-us/mailto.png" alt="" srcset="" class="code">
        <p class="email">service@futailnternational.com</p>
        <div class="down">
          <img src="@/assets/img/community/down.png" class="down-img" />
        </div>
      </div>
    </div>


    <div class="full-slider">
      <img src='@/assets/image/logo/FT.png' class="logo" />
      <p>{{ $t("aboutUs.whoAreWe.describe") }}</p>
    </div>

    <div class="tab">
      <ul>
        <li>
          <p>
            <span>01</span>
            <img src="@/assets/img/about/01.png">
          </p>
          <div class="tit-word">
            {{ $t("aboutUs.whoAreWe.tab1") }}
          </div>
        </li>
        <li>
          <p>
            <span>02</span>
            <img src="@/assets/img/about/02.png">
          </p>
          <div class="tit-word">
            {{ $t("newAdd.tip15") }}
          </div>
        </li>
        <li>
          <p>
            <span>03</span>
            <img src="@/assets/img/about/03.png">
          </p>
          <div class="tit-word">
            {{ $t("newAdd.tip16") }}
          </div>
        </li>

      </ul>
    </div>





    <!-- <div class="info-group">
      <div class="container">
        <div class="info-group__left">
          <div>
            <div class="info-group__speed"></div>
            <span>{{ $t("aboutUs.whoAreWe.tab1") }}</span>
          </div>
        </div>
        <div class="info-group__right">
          <div class="info-group__zeros">
            <div class="info-group__zero" :class="{ 'info-group__zero-locale': $i18n.locale == 'en' }">
              <span>{{ $t("aboutUs.whoAreWe.tab2") }}</span>
            </div>
            <div class="info-group__zero" :class="{ 'info-group__zero-locale': $i18n.locale == 'en' }">
              <span>{{ $t("aboutUs.whoAreWe.tab3") }}</span>
            </div>
          </div>
          <div class="info-group__asic"><a href="/dist/Certificate.jpg" target="_blank">
              <div class="info-group__asic-pic"></div>
              <span>{{ $t("aboutUs.whoAreWe.tab4") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div class="container map-container">
      <h4>{{ $t("aboutUs.whoAreWe.title2") }}</h4>
      <div class="map">
        <i :class="{ 'map__zero-locale': screenWidth < 992 }"></i>
      </div>
    </div> -->
  </div>
</template>

<script>
import AboutBan from './AboutBan.vue';
export default {
  name: 'who-are-we',
  computed: {
    screenWidth() {
      let width = localStorage.getItem('screen_width') || 1200
      return width
    },
  },
  components: {
    AboutBan,
  },
  computed: {
    title() {
      return this.$t('header.aboutUs');
    }
  },
  // data() {
  //   return {
  //     title: this.$t("header.aboutUs")
  //   }
  // }
}
</script>
<style lang="scss" src="./about.scss" scoped></style>
<style scoped lang="scss">
.banner {
  padding-bottom: 100px;

  [class^='banner'] {
    text-align: center;
  }

  &__title {
    color: #000;
    font-size: 36px;
    font-weight: bold;
    position: relative;
    height: 50px;
    line-height: 50px;

    &::after {
      content: '';
      display: inline-block;
      width: 40px;
      height: 6px;
      background: #0D73D6;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &__sub-title {
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #00000086;
    line-height: 22px;
    margin-top: 22px;
  }

  &__pics {
    margin-top: 72px;
    height: 230px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 60%;
    @include bg-image('../../assets/image/about-us/who-banner-bg');
  }
}

.info-group {
  height: 720px;
  background: #F5F6F8;
  padding: 80px 0;

  .container {
    display: flex;
  }

  span {
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 22px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    line-height: 22px;

  }

  &__left {
    width: 360px;
    height: 560px;
    background-size: 360px 333px;
    background-repeat: no-repeat;
    padding-top: 333px;
    margin-right: 20px;
    @include bg-image('../../assets/image/about-us/who-global');
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);
    border-radius: 2px;

    &>div {
      height: 100%;
      padding-top: 33px;
      background-color: #fff;
    }
  }

  &__speed {
    height: 72px;
    width: 157px;
    margin: 0 auto 12px;
    @include bg-image('../../assets/image/about-us/who-speed');
  }

  &__zeros {
    display: flex;
  }

  &__zero {
    width: 360px;
    height: 261px;
    background-color: #fff;
    border-radius: 2px;
    @include bg-image('../../assets/image/about-us/who-zero-2');
    background-repeat: no-repeat;
    background-size: 103px 72px;
    background-position: 140px 79px;
    position: relative;
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);

    &:first-child {
      margin-right: 20px;
      background-color: #0D73D6;
      @include bg-image('../../assets/image/about-us/who-zero-1');

      span {
        color: rgba(255, 255, 255, .6);
        padding: 0 10px;
      }
    }

    span {
      position: absolute;
      bottom: 76px;
    }
  }

  &__asic {
    margin-top: 20px;
    background-color: #fff;
    width: 740px;
    height: 279px;
    box-shadow: 0 5px 30px 0 rgba(73, 86, 117, 0.1);
    border-radius: 2px;
    @include bg-image('../../assets/image/about-us/who-asic');
    background-repeat: no-repeat;
    background-size: 459px 279px;
    padding-left: 460px;
    position: relative;

    &-pic {
      width: 120px;
      height: 40px;
      @include bg-image('../../assets/image/about-us/who-asic-icon');
      background-size: 120px 40px;
      transform: translate3d(60px, 100px, 0);

      &+span {
        position: absolute;
        bottom: 95px;
        left: 520px;
        width: auto;
      }
    }
  }
}

.info-group__zero-locale {
  &:nth-child(1) {
    @include bg-image('../../assets/image/about-us/who-zero-1-en');
    background-repeat: no-repeat;
    background-size: 168px 72px;
    background-position: 109px 79px;
  }

  &:nth-child(2) {
    @include bg-image('../../assets/image/about-us/who-zero-2-en');
    background-repeat: no-repeat;
    background-size: 168px 72px;
    background-position: 109px 79px;
  }


}

// .full-slider {
//   display: flow-root;

//   .logo {
//     margin: 100px auto 40px auto;
//     width: 191px;
//     display: block;
//   }

//   p {
//     width: 975px;
//     height: auto;
//     background: url(../../assets/img/about/bg2.png) no-repeat center center;
//     border-radius: 10px;
//     overflow: hidden;
//     background-size: 100% 100%;
//     padding: 32px 20px;
//     font-size: 18px;
//     font-weight: bold;
//     line-height: 36px;
//     color: #ffffff;
//     margin: 0 auto;

//   }
// }

.map-container {
  h4 {
    height: 50px;
    font-size: 36px;
    font-weight: bold;
    color: #000000;
    line-height: 50px;
    text-align: center;
    margin: 80px 0 38px 0;
  }

  .map {
    width: 800px;
    height: 373px;
    margin: 0 auto 80px;
    @include bg-image('../../assets/image/about-us/who-map');
    position: relative;

    i {
      position: absolute;
      right: 110px;
      bottom: 70px;
      width: 10px;
      height: 10px;
      background-color: #0D73D6;
      border-radius: 50%;
    }

    .map__zero-locale {
      width: 5px;
      height: 5px;
      right: 10vw;
      bottom: 35px;
    }
  }
}

@media screen and (min-width: 992px) and(max-width: 1200px) {
  .info-group__left {
    width: 400px;
    background-position: center top;
  }
}

@media screen and(max-width: 992px) {
  .service {
    width: 80%;
    padding-top: 50px;
    flex-wrap: wrap;
    margin: 0 auto;
    flex-direction: column-reverse;
    gap: 40px;

    h4 {
      font-size: 18px;
    }

    .service_left,
    .service_right {
      width: 100%;
    }

    .service_left {
      .code {
        width: 180px;
        height: 180px;
      }

      .up,
      .down img {
        width: 20px;
      }

      p {
        font-size: 13px;
        line-height: 22px;
      }
    }

    .service_right {
      width: 208px;
      height: auto;

      .top-img {
        width: 45px;
        height: 40px;
      }
    }
  }

  .container {
    width: 100%;
  }

  .banner {
    padding-bottom: 0px;
  }

  .banner__title {
    font-size: 26px;
  }

  .banner__sub-title {
    margin-top: 10px;
  }

  .banner__pics {
    width: 90vw;
    margin: 0 auto;
  }

  .info-group {
    padding: 20px 10px;
    height: 260px;

    .info-group__left {
      height: 220px;
      width: 30%;
      background-size: 100% 110px;
      padding-top: 110px;
      margin-right: 10px;

      >div {
        width: 100%;
        padding-top: 0;

        .info-group__speed {
          background-size: 70%;
          background-position: center center;
          background-repeat: no-repeat;
          height: 55px;
          width: 100%;
          margin-bottom: 0;
        }

        span {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }

    .info-group__right {
      width: calc(70% - 10px);
      height: 220px;

      .info-group__zero {
        width: calc((100% - 10px)/2);
        height: 105px;
        margin-right: 10px;
        background-size: 40%;
        background-position: center 10px;

        &:nth-child(2) {
          margin-right: 0;
        }

        span {
          font-size: 13px;
          top: 50%;
        }
      }

      .info-group__asic {
        margin-top: 10px;
        height: 105px;
        width: 100%;
        padding-left: 0;
        background-size: 50%;
        background-position: left center;
        position: relative;

        .info-group__asic-pic {
          width: 45px;
          height: 15px;
          position: absolute;
          right: 20px;
          top: 30px;
          background-repeat: no-repeat;
          background-size: cover;
          transform: none;
        }

        span {
          position: absolute;
          right: 20px;
          top: 50px;
          left: inherit;
          bottom: inherit;
          font-size: 13px;
          color: #000;
          display: block;
          height: 20px;
          width: 120px;
          text-align: right;
        }
      }
    }
  }



  .map-container {
    width: 100vw;

    h4 {
      min-height: 40px;
      height: 30px;
      font-size: 26px;
      color: #000;
      line-height: 30px;
      margin: 0;
      text-align: center;
      margin-top: 20px;
    }

    .map {
      width: 90%;
      height: 170px;
      background-size: cover;
      background-position: center center;
    }
  }

}
</style>
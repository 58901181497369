<template>
  <div class="home-footer">
    <FooterBan></FooterBan>
    <footer-nav />
    <disclaimer />
  </div>
</template>

<script>
import FooterBan from '../FooterBan.vue';
import FooterNav from './nav'
import Disclaimer from './disclaimer'
export default {
  name: 'home-footer',

  components: {
    FooterBan,
    FooterNav,
    Disclaimer,
  }
}
</script>

<style scoped lang="scss">
.home-footer {
 
}
</style>